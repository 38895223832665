/** @format */

export const Support = () => {

    return (
        <section id="support" className="support-area">
        
    </section>
    );
  };
  
  export default Support;
  